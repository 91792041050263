<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">{{ $store.getters['languageMixin/getStringFromLanguage']('Email templates List') }}</h4>
          <div class="d-flex justify-content-start">
            <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['email-templates/create'])"
                         :to="'/email-templates/create'"
                         class="btn btn-info btn-wd" wide>
              {{ $store.getters['languageMixin/getStringFromLanguage']('Add New') }}
              <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
            </router-link>
          </div>
        </div>

        <!--    filters    -->
        <!-- <div class="row col-12 mb-2">
          <div class="col-md-4 col-sm-4">
            <fg-select
              size="large"
              filterable
              clearable
              :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Select Training')"
              :input-classes="'select-default'"
              :list="filterBuilders.trainingList"
              :listItemLabel="'title'"
              :listItemValue="'id'"
              v-model="filters.training_id">
            </fg-select>
          </div>

          <div class="col-md-2 col-sm-4">
            <l-button class="mr-lg-1" @click="applyFilter()" type="info">{{ $store.getters['languageMixin/getStringFromLanguage']('Filter Result') }}</l-button>
          </div>
        </div> -->
        <!--  end  filters    -->

        <div>
          <general-data-table
            ref="table"
            :key="componentKey"
            :api-url="'email-templates/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              :label="$store.getters['languageMixin/getStringFromLanguage']('Actions')">
              <template slot-scope="scope">

                <template>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['email-templates/update'])"
                               v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Edit')"
                               class="btn-warning btn-simple btn-link"
                               :to="'/email-templates/edit/'+scope.row.id">
                    <i class="fa fa-edit"></i>
                  </router-link>

                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['email-templates/preview'])"
                               v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Preview')"
                               class="btn-warning btn-simple btn-link"
                               :to="'/email-templates/preview/'+scope.row.id">
                    <i class="fa fa-eye"></i>
                  </router-link>

                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['email-templates/delete'])"
                     v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Delete')"
                     class="btn-danger btn-simple btn-link"
                     @click="openDeleteModal(scope.row.id)">
                    <i class="fa fa-times"></i></a>
                </template>


              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="$store.getters['languageMixin/getStringFromLanguage']('Are you sure you want to delete this Template?')"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";


export default {
  components: {
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    FgSelect,
  },

  data() {

    return {
      tableColumns: [
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Lang'), value: 'lang', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Target'), value: 'target', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Subject'), value: 'subject', minWidth: '200', align: 'center'},
      ],

      filterBuilders: {
        trainingList: []
      },
      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
    }
  },
  async mounted() {
    let data = {};
    // let response = await this.axios.post("email-templates/builder", data);
    // this.filterBuilders.trainingList = response.data.trainings;
  },
  methods: {
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    async confirmDeleteModal() {
      try {
        let data = {
            'id': this.toDeleteId,
        }
        await this.axios.delete("email-templates/delete", {headers: {}, data});
        this.$refs.table.getData("updateData");
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("Email templates deleted successfully"),
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },

    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.training_id) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      this.$refs.table.getData("updateData");
    },
  },
}
</script>

