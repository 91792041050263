<template>

  <div class="row" ref="groupForm">
    <div class="col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <ValidationProvider
                  vid="fr.lang"
                  rules="required"
                  name="The Language"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="lang"
                    size="large"
                    filterable
                    clearable
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Language')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Language')"
                    :list="builderData.languageList"
                    :listItemLabel="'label'"
                    :listItemValue="'value'"
                    v-model="formData.lang">
                  </fg-select>
                </ValidationProvider>
              </div>
              <div class="col-md-4">
                <ValidationProvider
                  vid="fr.target"
                  rules="required"
                  name="The Target"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="lang"
                    size="large"
                    filterable
                    clearable
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Sent From')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Sent From')"
                    :list="builderData.targetsList"
                    :listItemLabel="'label'"
                    :listItemValue="'value'"
                    v-model="formData.target">
                  </fg-select>
                </ValidationProvider>
              </div>
              <div class="col-md-4">
                <label class="card-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Action') }}</label>
                <ValidationProvider
                  vid="fr.action"
                  rules=""
                  name="The Action"
                  v-slot="{ passed, failed,errors }">
                  <fg-input
                    name="lang"
                    type="text"
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Action')"
                    :error="failed ? errors[0]: null"
                    v-model="formData.action">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label class="card-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Subject') }}</label>
                <ValidationProvider
                  vid="fr.subject"
                  rules="required"
                  name="The Subject"
                  v-slot="{ passed, failed,errors }">
                    <fg-input v-model="formData.subject"
                              type="text"
                              :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Subject')"
                              :error="failed ? errors[0]: null"
                    >
                    </fg-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Email content in HTML format') }}</label>
                  <editor
                    v-model="formData.html"
                    :api-key="editorKey"
                    :init='editorConfig'
                    @onKeyUp="generatePlainText"
                  />
                </div>
              </div>

              <div class="col-md-6">
                
                <label class="card-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Email content in plain text format') }}</label>
                <div class="generate-text">
                  <input type="checkbox" id="generate-text">
                  <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Automatically generate plain text format') }}</label>
                </div>
                <ValidationProvider
                  vid="fr.text"
                  rules="required"
                  name="The Text"
                  v-slot="{ passed, failed,errors }">
                    <fg-text v-model="formData.text"
                              type="text"
                              :placeholder="$store.getters['languageMixin/getStringFromLanguage']('text')"
                              :error="failed ? errors[0]: null"
                    >
                    </fg-text>
                </ValidationProvider>
              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Submit') }}
            </l-button>
            <l-button @click="$router.push('/email-templates/list')" type="danger" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Cancel') }}
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";
import Editor from '@tinymce/tinymce-vue'

extend("required", {
  message: "{_field_} is required"
});

export default {
  components:{
    FgSelect,
    'editor': Editor
  },
  data() {
    return {
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        lang :'',
        target : '',
        action : '',
        subject: '',
        text: '',
        html: '',
       is_active: true
      },
      builderData: {
        languageList: [],
        targetsList: []
      },
    };
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.groupForm
    });

    let data = {}
    this.axios.post("email-templates/builder", data).then((response) => {
      this.builderData.languageList = response.data.languages;
      this.builderData.targetsList = response.data.targets;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Edit Email Template");
        this.getItem();
      } else {
        this.editMode = false;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Add Email Template");
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getItem() {
      let data = {
        'id': this.id,
      };
      this.axios.post("email-templates/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: this.$store.getters['languageMixin/getStringFromLanguage']("Email Template Not Found"),
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("email-templates/update/" + this.id, this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Email Template Updated Successfully");
      } else {
        request = this.axios.post("email-templates/create", this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Email Template Added Successfully");
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/email-templates/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    generatePlainText(){
      console.log(document.getElementById('generate-text').checked)
      if (this.formData.html && document.getElementById('generate-text').checked) {
        let d = document.createElement('div');
        d.innerHTML = this.formData.html;
        this.formData.text = d.innerText;
      }
    }
  }
}
</script>

<style>
.generate-text{
  display: flex;
    align-items: center;
    margin: 7px 0;
}

.generate-text label{
  padding-left: 8px;
    margin: 0;
}
</style>
